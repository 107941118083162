/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from 'react';
import cn from 'classnames';
import { t } from 'i18next';
import NumberInput from 'components/common/number.input-new';
import { UnaccompaniedMinorCheckbox } from 'entities/cart/ui/UnaccompaniedMinorCheckbox';

export function CheckedUnaccompaniedMinorForm({
    passengers,
    passengersTotal,
    isFocused,
    maxCount,
    isCheckedUnaccompaniedMinor,
    error,
    handleFocus,
    handleMouseDown,
    handleKeyDown,
    setInputEl,
    onChangeAdultsCount,
    onChangeChildrenCount,
    onCheckedUnaccompaniedMinor,
}) {
    const { adultsCount, childrenCount } = passengers;

    return (
        <div className="SearchWidget-box -passengers">
            <input
                onFocus={handleFocus}
                onMouseDown={handleMouseDown}
                onKeyDown={handleKeyDown}
                ref={setInputEl}
                className={cn(`SearchWidget-input -select`, {
                    'is-error': error && !isFocused,
                })}
                type="text"
                value={t('booking.passenger_interval', {
                    postProcess: 'interval',
                    count: passengersTotal,
                })}
                readOnly
                tabIndex="1"
            />

            <div className="SearchWidget-dropdown -padding" aria-hidden={!isFocused}>
                <div className="Grid-2 -middle -nogutter !mb24">
                    <p className="Col unaccompaniedMinor">{t(`booking.teenagerUnaccompaniedMinor`)}</p>
                    <div className="Col">
                        <NumberInput
                            key={`children${childrenCount}`}
                            className="block-right"
                            value={adultsCount}
                            minValue={0}
                            maxValue={maxCount.adults}
                            onChange={onChangeAdultsCount}
                        />
                    </div>
                </div>
                <div className="Grid-2 -middle -nogutter !mb24">
                    <p className="Col unaccompaniedMinor">{t(`booking.childrenUnaccompaniedMinor`)}</p>
                    <div className="Col">
                        <NumberInput
                            key={`children${childrenCount}`}
                            className="block-right"
                            value={childrenCount}
                            minValue={0}
                            maxValue={maxCount.children}
                            onChange={onChangeChildrenCount}
                        />
                    </div>
                </div>
                <div className="Grid-2 -middle -nogutter">
                    <div className="Col line">
                        <UnaccompaniedMinorCheckbox
                            isChecked={isCheckedUnaccompaniedMinor}
                            onChange={onCheckedUnaccompaniedMinor}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
