import { SERVICE_TYPE } from './consts';
import { findService } from './lib';
import type { Applicability, CheckinService, Service, ServiceType } from './types';
import type { ServiceItemSegment } from './ui/ServiceItem/index';

interface ServiceInfoParamsProps {
    cartId: string;
    segments: ServiceItemSegment[];
    guid: string;
    serviceType: ServiceType;
}

interface ServiceInfoParams {
    // eslint-disable-next-line camelcase
    cart_uuid: string;
    guid: string;
    type: ServiceType;
    cost: string[];
    segments?: string[];
    direction?: string | null;
}

interface SavedService {
    // eslint-disable-next-line camelcase
    segment_id_asm: string;
    // eslint-disable-next-line camelcase
    passenger_id_asm: string;
    guid: string | undefined;
    count: number | undefined;
    cost: number;
}

const TICKET_DIRECTION = {
    ONE_WAY: 'ow',
    ROUND_TRIP: 'rt',
};

const DIRECTION = {
    TO: 'to',
    BACK: 'back',
};

export function getServiceLoadedParams(cartId: string, services: Service[], segments: ServiceItemSegment[]) {
    const guids = services.map(({ guid }) => guid);
    const ticketDirection = segments.some(({ direction }) => direction === DIRECTION.BACK.toUpperCase())
        ? TICKET_DIRECTION.ROUND_TRIP
        : TICKET_DIRECTION.ONE_WAY;

    return {
        cart_uuid: cartId,
        direction: ticketDirection,
        guid: guids,
    };
}

function getSegmentsCityCodes(segments: ServiceItemSegment[]) {
    return segments.map((segment) => `${segment.departureAirportCode}_${segment.arrivalAirportCode}`);
}

function getCostOnSegments(segments: ServiceItemSegment[]) {
    return segments.map((segment) => segment.passengers.map(({ price }) => price).toString());
}

function getDirection(segments: ServiceItemSegment[]) {
    const counts = { TO: 0, BACK: 0 };

    segments.forEach(({ direction }) => {
        if (direction === 'TO' || direction === 'BACK') {
            counts[direction] += 1;
        }
    });

    if (counts.TO && counts.BACK) {
        return TICKET_DIRECTION.ROUND_TRIP;
    }

    if (counts.TO) {
        return DIRECTION.TO;
    }

    if (counts.BACK) {
        return DIRECTION.BACK;
    }

    return null;
}

export function getServiceInfoParams({ cartId, segments, guid, serviceType }: ServiceInfoParamsProps) {
    const cost = getCostOnSegments(segments);

    const params: ServiceInfoParams = {
        cart_uuid: cartId,
        guid,
        type: serviceType,
        cost,
    };

    if (serviceType === SERVICE_TYPE.MEAL) {
        params.segments = getSegmentsCityCodes(segments);
    }

    if (serviceType === SERVICE_TYPE.BAGGAGE) {
        params.direction = getDirection(segments);
    }

    return params;
}

function getOnlyAddedServices(services: CheckinService[]) {
    return services.filter(({ quantity }) => quantity > 0);
}

export function getServiceSaveClickedParams({
    cartId,
    segments,
    applicability,
    guid,
    diffServices,
    serviceType,
}: {
    cartId: string;
    segments: ServiceItemSegment[];
    applicability: Applicability[];
    guid: string;
    diffServices: CheckinService[];
    serviceType: ServiceType;
}) {
    const onlyAddedServices = getOnlyAddedServices(diffServices);
    const passengersIds = onlyAddedServices.map(({ passengerIdAsm }) => passengerIdAsm);
    const uniqPassengersIds = [...new Set(passengersIds)];
    const direction = getDirection(segments);

    const savedServices: SavedService[][] = segments.reduce((acc: SavedService[][], segment) => {
        const costOnSegment: SavedService[] = segment.passengers.reduce((accPassenger: SavedService[], passenger) => {
            const { quantity, guid: passengerServiceGuid } =
                findService(diffServices, segment.segmentIdAsm, passenger.passengerIdAsm) || {};
            const { price } = findService(applicability, segment.segmentIdAsm, passenger.passengerIdAsm) || {};

            if (quantity || passengerServiceGuid) {
                accPassenger.push({
                    segment_id_asm: segment.segmentIdAsm,
                    passenger_id_asm: passenger.passengerIdAsm,
                    guid: passengerServiceGuid,
                    count: quantity,
                    cost: price ? (quantity! * price) / 100 : 0,
                });
            }

            return accPassenger;
        }, []);

        if (costOnSegment.length) {
            acc.push(costOnSegment);
        }

        return acc;
    }, []);

    return {
        cart_uuid: cartId,
        guid,
        type: serviceType,
        passenger_id_asm: uniqPassengersIds,
        saved_services: savedServices,
        ...(serviceType === SERVICE_TYPE.BAGGAGE && { direction }),
        ...(serviceType === SERVICE_TYPE.MEAL && { segment: getSegmentsCityCodes(segments) }),
    };
}

export function getServiceCrossClickedParams({
    cartId,
    guid,
    serviceType,
}: {
    cartId: string;
    guid: string;
    serviceType: ServiceType;
}) {
    return {
        cart_uuid: cartId,
        guid,
        type: serviceType,
    };
}

export function getBackClickedParams(cartId: string) {
    return {
        cart_uuid: cartId,
    };
}

export function getContinueClickedParams({
    cartId,
    services,
    checkinServices,
    countName,
}: {
    cartId: string;
    services: Service[];
    checkinServices: CheckinService[];
    countName: string;
}) {
    const onlyNeededCheckinServices = checkinServices.filter((checkinService) =>
        services.some((service) => checkinService.guid === service.guid)
    );

    const count = onlyNeededCheckinServices.map(({ quantity }) => quantity);
    const cost = onlyNeededCheckinServices.reduce((acc, checkinService) => {
        const service = services.find((serviceItem) => serviceItem.guid === checkinService.guid);
        const applicability = service?.applicability;

        if (!applicability) {
            return acc;
        }

        const price =
            findService(applicability, checkinService.segmentIdAsm, checkinService.passengerIdAsm)?.price || 0;

        return acc + (price * checkinService.quantity) / 100;
    }, 0);

    return {
        cart_uuid: cartId,
        [countName]: count,
        cost,
    };
}
