import React, { useMemo, useReducer } from 'react';
import { parseQueryString } from 'utils';

import SignUp from './SignUp';
import LogIn from './LogIn';
import LogInConfirm from './LogInConfirm';
import { CHANNELS, STEPS } from './consts';

function reducer(state, action) {
    const { type, payload } = action;
    const { __step, data } = payload;

    switch (type) {
        case 'next':
            return { step: __step, ...data };
        case 'error':
            return state;
        default:
            return state;
    }
}

export default function Account({ setIsLoading, step = STEPS.LOGIN }) {
    const query = useMemo(
        () =>
            parseQueryString(
                window.location.search.startsWith('?') ? window.location.search.slice(1) : window.location.search
            ),
        []
    );
    const [state, dispatch] = useReducer(reducer, {
        step,
        channel: query?.channel || CHANNELS.PHONE,
        address: query?.address || '',
        isSignUpDefault: step === STEPS.SIGNUP,
    });
    let Component;

    switch (state.step) {
        case STEPS.SIGNUP:
            Component = SignUp;
            break;
        case STEPS.LOGIN:
            Component = LogIn;
            break;
        case STEPS.LOGIN_CONFIRM:
            Component = LogInConfirm;
            break;
        default:
            Component = LogIn;
    }

    return (
        <Component
            {...state}
            setIsLoading={setIsLoading}
            onNext={(payload) => dispatch({ type: 'next', payload })}
            onError={(payload) => dispatch({ type: 'error', payload })}
        />
    );
}
