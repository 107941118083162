export const STEPS = {
    LOGIN: 'LogIn',
    LOGIN_CONFIRM: 'LogInConfirm',
    SIGNUP: 'SignUp',
};

export const CHANNELS = {
    EMAIL: 'email',
    PHONE: 'phone',
};
