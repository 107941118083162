/* eslint-disable */
import { createFSA } from 'utils';
import { utairHttpManager as request } from 'managers/utair';

import * as TYPE from 'constants/subscriptionsTypes';
import { API_URLS } from 'consts';

export const updateUserSubscriptions = createFSA(TYPE.UPDATE_USER_SUBSCRIPTIONS);

/**
 * User actions
 */
export const fetchUserFetchFSA = createFSA(TYPE.FETCH_USER_SUBSCRIPTIONS);
export const fetchUserFetchStateFSA = createFSA(TYPE.SET_USER_FETCH_STATE);
export const setUserSubscriptionsFSA = createFSA(TYPE.SET_USER_SUBSCRIPTIONS);
export const userSubscriptionsErrorFSA = createFSA(TYPE.SET_USER_ERROR);

export const fetchUserSubscriptions = () => (dispatch) => dispatch(fetchUserFetchFSA());

export const requestUserSubscriptions = () =>
    request.get(`${API_URLS.PROFILE.SUBSCRIPTIONS}`, null, true).then((res) => res.data);

export const updateUserSubscriptionsRequest = (params) =>
    request.put(`${API_URLS.PROFILE.SUBSCRIPTIONS}`, params, true).then((res) => res.data);
