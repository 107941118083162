import React, { useState, useEffect } from 'react';
import { Button, Input } from '@public-projects/utair-ui-kit';
import i18next from 'i18next';
import { ERROR_TYPES } from 'constants/errorTypes';
import { ConfirmAttemptsExceededError } from 'components/Account/ConfirmAttemptsExceededError';

import { validator } from '../common';
import { authLogin, confirmAttemptsExceededError, signUpUser } from './queries';
import RadioSelector from '../common/InputSelector/RadioSelector';
import PhoneInput from '../common/InputSelector/PhoneInput';
import { Agreement } from './Agreement';
import accountStyles from './Account.module.scss';
import styles from './SingUp.module.scss';
import { STEPS, CHANNELS } from './consts';

const getSignUpError = ({ code: errorCode }, type) => {
    if (errorCode === confirmAttemptsExceededError) {
        return ConfirmAttemptsExceededError;
    }

    const errorCodeMap = {
        [ERROR_TYPES.CONFLICT]: i18next.t(`sign.error.social_${type}_exists`),
        [ERROR_TYPES.BAD_REQUEST]: i18next.t('validator.errors.phone_format'),
        [ERROR_TYPES.INTERNAL_SERVER_ERROR]: i18next.t('common.unknown_error'),
    };

    return errorCodeMap[errorCode] || i18next.t('sign.error.api_error');
};

export default function SignUp({ setIsLoading, channel = CHANNELS.PHONE, address, isSignUpDefault, onNext }) {
    const [login, setLogin] = useState(address);
    const [loginError, setLoginError] = useState('');
    const [signUpType, setSignUpType] = useState(channel);
    const [isSignUpProcess, setIsSignUpProcess] = useState(false);
    const [isAgree, setIsAgree] = useState(false);
    const [GDPRAgreementDate, setGDPRAgreementDate] = useState(null);

    useEffect(() => {
        const timestampInSec = Math.floor(Date.now() / 1000);
        setGDPRAgreementDate(isAgree ? timestampInSec : null);
    }, [isAgree]);

    useEffect(() => {
        setLogin(signUpType === channel ? address || '' : '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signUpType]);

    useEffect(() => {
        if (!isSignUpProcess) return;

        async function signUp() {
            try {
                setIsLoading(true);
                await signUpUser(signUpType, login, GDPRAgreementDate);
                const data = await authLogin(login);
                onNext({ __step: STEPS.LOGIN_CONFIRM, data: { ...data, login, isSignUp: true } });
            } catch (error) {
                setLoginError(getSignUpError(error, signUpType));
                setIsSignUpProcess(false);
            } finally {
                setIsLoading(false);
            }
        }

        signUp();
    }, [isSignUpProcess]);

    useEffect(() => {
        setLoginError('');
    }, [login, signUpType]);

    const handleSignUp = () => {
        const validationResult = validator[signUpType](login, true);

        if (validationResult === true) {
            setIsSignUpProcess(true);
        } else {
            setLoginError(validationResult);
        }
    };

    const handleChangeEmail = ({ target: { value } }) => {
        setLogin(value.trim());
    };

    const handleChangePhone = (value) => {
        setLogin(value);
    };

    const handleBlurLogin = () => {
        const validationResult = validator[signUpType](login, true);

        if (typeof validationResult === 'string') {
            setLoginError(validationResult);
        }
    };

    return (
        <div className={accountStyles.form}>
            <h3 className={accountStyles.title}>Регистрация</h3>

            <div className={styles.radioButtonsWrapper}>
                <RadioSelector
                    items={{
                        [CHANNELS.PHONE]: 'Телефон',
                        [CHANNELS.EMAIL]: 'Email',
                    }}
                    selected={signUpType}
                    onChange={setSignUpType}
                />
            </div>

            <div className={accountStyles.wrapper}>
                {signUpType === CHANNELS.PHONE ? (
                    <PhoneInput
                        value={login}
                        onChange={handleChangePhone}
                        onBlur={handleBlurLogin}
                        message={loginError}
                        isError={!!loginError}
                    />
                ) : (
                    <Input
                        value={login}
                        onChange={handleChangeEmail}
                        onBlur={handleBlurLogin}
                        message={loginError}
                        isError={!!loginError}
                    />
                )}
            </div>

            <div className={styles.agreementWrapper}>
                <Agreement checked={isAgree} onChange={() => setIsAgree(!isAgree)} />
            </div>

            <div className={styles.buttonWrapper}>
                <Button disabled={!login || !isAgree} fullWidth onClick={handleSignUp}>
                    Зарегистрироваться
                </Button>
            </div>

            {!isSignUpDefault && (
                <div className={styles.buttonWrapper}>
                    <Button fullWidth variant="text" onClick={() => onNext({ __step: STEPS.LOGIN })}>
                        Войти
                    </Button>
                </div>
            )}
        </div>
    );
}
