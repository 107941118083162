/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { browserHistory } from 'react-router'; // eslint-disable-line no-unused-vars
import { Button, ConfirmCode } from '@public-projects/utair-ui-kit';
import { ERROR_TYPES } from 'constants/errorTypes';
import i18next from 'i18next';
import get from 'lodash/get';
import { validator } from 'components/common/validator';
import { API_URLS, CLIENT_SECRET, CLIENT_ID, DELAY } from 'consts';
import { setSessionData, initAuth } from 'actions/auth';
import { getUserData } from 'actions/user';
import { useBonusesLink } from 'utils/hooks';

import { getSocialData } from './social/selectors';
import { utairHttpManager as request } from '../../managers/utair';
import { createFormURLEncoded } from '../../utils';
import fetch, { parseBody, parseError } from '../../utils/fetch';
import taisAuth from './taisAuth';
import { authLogin, getAuthConfirmError } from './queries';
import { SignUpAgreement } from './SignUpAgreement';
import styles from './Account.module.scss';
import { STEPS } from './consts';

function LogInConfirm({
    setIsLoading,
    login,
    checkType,
    channel,
    attempt_id,
    onNext = () => {},
    isSignUp,
    socialData,
}) {
    const dispatch = useDispatch();
    const [code, setCode] = useState('');
    const [confirm, doConfirm] = useState('');
    const [error, setError] = useState('');
    const [isButtonDisabled, setIsButtonDisable] = useState(true);

    const bonusesLink = useBonusesLink();

    const resendCode = () => {
        authLogin(login)
            .then((data) => {
                onNext({ __step: STEPS.LOGIN_CONFIRM, data: { ...data, login } });
            })
            .then(() => {
                setCode('');
                doConfirm(false);
                setIsButtonDisable(true);
            })
            .catch(parseError)
            .catch((data) => {
                const errorCode = get(data, 'data.meta.error_code');
                setError(getAuthConfirmError(errorCode));
            });
    };

    const inputHandler = (value) => {
        setCode(value);
        setError('');
        doConfirm(false);
        setIsButtonDisable(false);
    };

    const blurHandler = (event) => {
        const value = event.target.value.replaceAll('_', '');

        if (!value) {
            setError('');
            setIsButtonDisable(true);

            return;
        }

        const validCode = validator.code(value, 4, false);

        if (validCode !== true) {
            setError(validCode);
            setIsButtonDisable(true);
        }
    };

    const errorCodeHandler = (value) => {
        try {
            switch (value.code) {
                case ERROR_TYPES.FORBIDDEN:
                    setError(i18next.t('sign.error.too_many_requests'));
                    break;

                case ERROR_TYPES.INTERNAL_SERVER_ERROR:
                    setError(i18next.t('common.unknown_error'));
                    break;

                default:
                    setError(i18next.t('sign.error.incorrect_code'));
                    break;
            }
        } catch (e) {
            console.error(e); // eslint-disable-line no-console
            setError(i18next.t('common.unknown_error'));
        }

        doConfirm(false);
        setIsLoading(false);
        setIsButtonDisable(true);
    };

    const submitHandler = (event) => {
        event.preventDefault();
        doConfirm(true);
    };

    useEffect(() => {
        if (!confirm) return;
        setIsLoading(true);
        let params = { attempt_id, code };

        if (socialData) {
            params = { ...params, ...socialData };
        }

        request
            .post(API_URLS.AUTH.LOGIN_CONFIRM, params, false)
            .then(({ session }) =>
                fetch(API_URLS.AUTH.TOKEN, {
                    method: 'POST',
                    headers: {
                        Authorization: `Basic ${btoa(`${CLIENT_ID}:${CLIENT_SECRET}`)}`,
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: createFormURLEncoded({
                        grant_type: 'password',
                        username: login,
                        password: session,
                    }),
                })
            )
            .then(parseBody)
            .then((sessionData) => {
                taisAuth(sessionData);
                setSessionData(sessionData);
            })
            .then(() => {
                dispatch(initAuth());
                setIsLoading(false);
                const { pathname, search } = window.location;

                if (
                    pathname === '/status/about' ||
                    pathname.includes('/payment/') ||
                    pathname.includes('check-in-new') ||
                    pathname.includes('upgrade') ||
                    pathname.match(/\/orders\/services\/[-\w]+/)
                ) {
                    dispatch(getUserData(true));

                    return;
                }

                if (search.includes('auth_modal=true')) {
                    window.history.back();

                    return;
                }

                if (pathname.includes('acceptInvite')) {
                    window.location.reload();

                    return;
                }

                window.location.href = bonusesLink;
            })
            .catch(errorCodeHandler);
    }, [confirm]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <form className={styles.form} onSubmit={submitHandler}>
            <h3 className={styles.title}>{i18next.t(`sign.placeholder.enter_code_from_${channel}`)}</h3>
            <div className={styles.subtitle}>
                {i18next.t(`sign.placeholder.code_sent_to_${channel}`, {
                    login: checkType === 'cartStatus' ? '' : login,
                })}
            </div>
            <ConfirmCode
                className={styles.codeInput}
                onRequestCode={resendCode}
                onInput={inputHandler}
                onBlur={blurHandler}
                error={error}
                time={DELAY}
                timeLeftLabel="Выслать код повторно через:"
                repeatButtonLabel="Выслать код повторно"
                autoFocus
            />
            <Button type="submit" fullWidth size="large" disabled={isButtonDisabled}>
                {isSignUp ? 'Зарегистрироваться' : 'Войти'}
            </Button>

            {isSignUp && <SignUpAgreement />}

            <Button
                className={styles.button}
                fullWidth
                variant="text"
                onClick={() => onNext({ __step: isSignUp ? STEPS.SIGNUP : STEPS.LOGIN })}
            >
                Вернуться
            </Button>
        </form>
    );
}

const mapping = (state) => ({
    socialData: getSocialData(state),
});

export default connect(mapping)(LogInConfirm);
