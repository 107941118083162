/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from 'react';
import { t } from 'i18next';
import cn from 'classnames';
import styles from 'pages/main/SearchForm/Passengers.module.scss';
import NumberInput from 'components/common/number.input-new';
import { Tooltip } from '@public-projects/utair-ui-kit';
import { ReactComponent as InfoIcon } from 'icons/interface/info-filled.svg';

export function VacationPassengersForm({
    passengers,
    passengersTotal,
    isFocused,
    maxCount,
    handleFocus,
    handleMouseDown,
    handleKeyDown,
    setInputEl,
    onChangeAdultsCount,
    onChangeEmployeesCount,
    onChangeTeenagersCount,
    onChangeChildrenCount,
    onChangeInfantsCount,
}) {
    const { adultsCount, childrenCount, infantsCount, employeesCount = 0, teenagersCount = 0 } = passengers;

    return (
        <div className="SearchWidget-box -passengers">
            <input
                onFocus={handleFocus}
                onMouseDown={handleMouseDown}
                onKeyDown={handleKeyDown}
                ref={setInputEl}
                className="SearchWidget-input -select"
                type="text"
                value={t('booking.passenger_interval', {
                    postProcess: 'interval',
                    count: passengersTotal,
                })}
                readOnly
                tabIndex="1"
            />

            <div className="SearchWidget-dropdown" aria-hidden={!isFocused}>
                <div className={cn('Grid-2 -middle -nogutter', styles.inputGroup)}>
                    <p className="Col">{t('booking.employees')}</p>
                    <div className="Col">
                        <NumberInput
                            key={`employees${employeesCount}`}
                            className="block-right"
                            value={employeesCount}
                            minValue={adultsCount ? 0 : 1}
                            maxValue={maxCount.employees}
                            onChange={onChangeEmployeesCount}
                        />
                    </div>
                </div>
                <div className={styles.inputGroup}>
                    <span className={cn('caption block', styles.groupCaption)}>Дети сотрудника</span>
                    <div className={cn('Grid-2 -middle -nogutter', styles.groupItem)}>
                        <p className="Col">
                            Несовершеннолетний
                            <span className="caption block">12 — 18 лет</span>
                        </p>
                        <div className="Col">
                            <NumberInput
                                key={`teenagers${teenagersCount}`}
                                className="block-right"
                                value={teenagersCount}
                                minValue={0}
                                maxValue={maxCount.teenagers}
                                onChange={onChangeTeenagersCount}
                            />
                        </div>
                    </div>
                    <div className={cn('Grid-2 -middle -nogutter', styles.groupItem)}>
                        <p className="Col">
                            Ребенок
                            <span className="caption block">до 12 лет</span>
                        </p>
                        <div className="Col">
                            <NumberInput
                                key={`children${childrenCount}`}
                                className="block-right"
                                value={childrenCount}
                                minValue={0}
                                maxValue={maxCount.children}
                                onChange={onChangeChildrenCount}
                            />
                        </div>
                    </div>
                    <div className={cn('Grid-2 -middle -nogutter', styles.groupItem)}>
                        <p className="Col">
                            Младенец
                            <span className="caption block">до 2 лет без места</span>
                        </p>
                        <div className="Col">
                            <NumberInput
                                key={`infants${infantsCount}`}
                                className="block-right"
                                value={infantsCount}
                                minValue={0}
                                maxValue={maxCount.infants}
                                onChange={onChangeInfantsCount}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.inputGroup}>
                    <div className="Grid-2 -middle -nogutter">
                        <div className="Col">
                            <div className={styles.itemWrapper}>
                                {t('booking.relative')}
                                <Tooltip
                                    placement="bottom"
                                    className={styles.tooltipPopover}
                                    tooltip={
                                        <div className={styles.tooltip}>
                                            Супруг(а), дети 18+, родители, бабушки, дедушки, внуки, родные братья и
                                            сестры, лица над которыми оформлено опекунство, попечительство
                                        </div>
                                    }
                                    variant="primary"
                                    interactive
                                >
                                    <span className={styles.itemWrapper}>
                                        <InfoIcon className={styles.infoIcon} />
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="Col">
                            <NumberInput
                                key={`adults${adultsCount}`}
                                className="block-right"
                                value={adultsCount}
                                minValue={employeesCount ? 0 : 1}
                                maxValue={maxCount.adults}
                                onChange={onChangeAdultsCount}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
