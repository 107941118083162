/* eslint-disable */
import React from 'react';
import onClickOutside from 'react-onclickoutside';
import {
    FLIGHT_MAX_PASSENGERS_COUNT,
    FLIGHT_MAX_PASSENGERS_COUNT_CHILDREN_ALONE,
    FLIGHT_MAX_PASSENGERS_COUNT_WITH_STANDBY,
    FLIGHT_MAX_PASSENGERS_COUNT_WITH_VACATION,
} from 'constants/passengerTypes';
import { CheckedUnaccompaniedMinorForm } from 'pages/main/SearchForm/CheckedUnaccompaniedMinorForm';
import { VacationPassengersForm } from 'pages/main/SearchForm/VacationPassengersForm';
import { PassengersForm } from 'pages/main/SearchForm/PassengersForm';

/**
 * Do not employ onClick handler because it interfers with onFocus handler, and here we
 * preserve Tab key and mouse focusing, so they both function in parallel
 */
export class Passengers extends React.Component {
    state = {
        isFocused: false,
        isCheckedUnaccompaniedMinor: this.props.isCheckedUnaccompaniedMinor,
    };

    get maxCount() {
        const {
            passengers: { adultsCount, childrenCount, infantsCount, employeesCount = 0, teenagersCount = 0 },
            isStandby,
            isStandbyTicketType,
            isVacationTicketType,
        } = this.props;

        if (isVacationTicketType) {
            const total = FLIGHT_MAX_PASSENGERS_COUNT_WITH_VACATION;
            const currentTotal = adultsCount + teenagersCount + childrenCount + infantsCount + employeesCount;
            const availableToMax = total - currentTotal;
            const infantsLimit = adultsCount + employeesCount;

            const maxAdults = total - teenagersCount - childrenCount - infantsCount - employeesCount;
            const maxTeenagers = availableToMax + teenagersCount;
            const maxChildren = availableToMax + childrenCount;
            const maxInfants = total - adultsCount - teenagersCount - childrenCount - employeesCount;
            const maxEmployees = total - adultsCount - teenagersCount - childrenCount - infantsCount;

            return {
                adults: Math.min(2, maxAdults),
                teenagers: Math.min(total, maxTeenagers), // Родственник от 12 до 18 лет, далее считаем как adult, количество без ограничений
                children: Math.min(total, maxChildren),
                infants: Math.min(infantsLimit, maxInfants),
                employees: Math.min(1, maxEmployees),
            };
        }

        const getMaxPassengersTotal = () => {
            if (isStandby) return FLIGHT_MAX_PASSENGERS_COUNT_WITH_STANDBY;
            if (this.state.isCheckedUnaccompaniedMinor) return FLIGHT_MAX_PASSENGERS_COUNT_CHILDREN_ALONE;
            return FLIGHT_MAX_PASSENGERS_COUNT;
        };

        const total = getMaxPassengersTotal();

        const currentTotal = adultsCount + childrenCount + infantsCount + employeesCount;
        const availableToMax = total - currentTotal;
        const infantsLimit = adultsCount + employeesCount;

        const maxAdults = total - childrenCount - infantsCount - employeesCount;
        const maxTeenagers = availableToMax;
        const maxChildren = availableToMax + childrenCount;
        const maxInfants = total - adultsCount - childrenCount - employeesCount;
        const maxEmployees = total - adultsCount - childrenCount - infantsCount;

        if (isStandbyTicketType)
            return {
                adults: Math.min(2, maxAdults),
                children: Math.min(total, maxChildren),
                infants: Math.min(infantsLimit, maxInfants),
                employees: Math.min(1, maxEmployees),
            };

        return {
            adults: Math.min(total, total - childrenCount - infantsCount),
            children: Math.min(total, maxChildren),
            infants: Math.min(infantsLimit, maxInfants),
        };
    }

    componentDidMount() {
        this.props.onRef && this.props.onRef(this);
    }

    handleMouseDown = (e) => {
        e.preventDefault();
        this.props.toggleFocusedPassengers(!this.state.isFocused);
        if (this.state.isFocused) {
            this.setState({ isFocused: false }, () => this.inputEl.blur());
        } else {
            this.setState({ isFocused: true }, () => this.inputEl.focus());
        }
    };

    onChangeEmployeesCount = (value) => {
        this.props.setPassengers({ ...this.props.passengers, employeesCount: value });
    };

    onChangeAdultsCount = (value) => {
        this.props.setPassengers({ ...this.props.passengers, adultsCount: value });
    };

    onChangeTeenagersCount = (value) => {
        this.props.setPassengers({ ...this.props.passengers, teenagersCount: value });
    };

    onChangeChildrenCount = (value) => {
        this.props.setPassengers({ ...this.props.passengers, childrenCount: value });
    };

    onChangeInfantsCount = (value) => {
        this.props.setPassengers({ ...this.props.passengers, infantsCount: value });
    };

    onCheckedUnaccompaniedMinor = () => {
        this.setState({ isCheckedUnaccompaniedMinor: !this.state.isCheckedUnaccompaniedMinor });
        this.props.onCheckedUnaccompaniedMinor(!this.state.isCheckedUnaccompaniedMinor);
    };

    handleFocus = () => {
        this.setState({ isFocused: true });
    };

    /**
     * leave on Tab press
     */
    handleKeyDown = (e) => {
        if (e.keyCode === 9) {
            this.setState({ isFocused: false });
        }
    };

    /**
     * @uses react-onclickoutside
     */
    handleClickOutside = () => {
        this.setState({ isFocused: false });
    };

    focus = () => {
        this.inputEl.focus();
        this.handleFocus();
    };

    setInputEl = (el) => {
        this.inputEl = el;
    };

    render() {
        const { passengers, isStandbyTicketType, error, isVacationTicketType } = this.props;
        const { adultsCount, childrenCount, infantsCount, employeesCount = 0, teenagersCount = 0 } = passengers;
        const passengersTotal = adultsCount + childrenCount + infantsCount + employeesCount + teenagersCount;

        const isBenefitTicketType = isStandbyTicketType || isVacationTicketType;

        const commonProps = {
            passengers,
            passengersTotal,
            isFocused: this.state.isFocused,
            maxCount: this.maxCount,
            handleFocus: this.handleFocus,
            handleMouseDown: this.handleMouseDown,
            handleKeyDown: this.handleKeyDown,
            setInputEl: this.setInputEl,
            onChangeAdultsCount: this.onChangeAdultsCount,
            onChangeChildrenCount: this.onChangeChildrenCount,
        };

        const unaccompaniedMinorProps = {
            isCheckedUnaccompaniedMinor: this.state.isCheckedUnaccompaniedMinor,
            onCheckedUnaccompaniedMinor: this.onCheckedUnaccompaniedMinor,
        };

        if (this.state.isCheckedUnaccompaniedMinor && !isBenefitTicketType) {
            return <CheckedUnaccompaniedMinorForm {...commonProps} {...unaccompaniedMinorProps} error={error} />;
        }

        if (isVacationTicketType) {
            return (
                <VacationPassengersForm
                    {...commonProps}
                    onChangeEmployeesCount={this.onChangeEmployeesCount}
                    onChangeTeenagersCount={this.onChangeTeenagersCount}
                    onChangeInfantsCount={this.onChangeInfantsCount}
                />
            );
        }

        return (
            <PassengersForm
                {...commonProps}
                {...unaccompaniedMinorProps}
                isStandbyTicketType={isStandbyTicketType}
                onChangeEmployeesCount={this.onChangeEmployeesCount}
                onChangeInfantsCount={this.onChangeInfantsCount}
            />
        );
    }
}

export const WrappedPassengers = onClickOutside(Passengers);
