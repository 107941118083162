import React from 'react';
import store from 'store';
import { Route, IndexRedirect, IndexRoute } from 'react-router';
import { t } from 'i18next';
import DynamicImport from 'components/common/dynamic.import';
import Layout from 'components/layout';
import { bonusesRoutes } from 'pages/bonuses/routes';
import PaymentStatus from 'pages/PaymentStatus';
import { landingsRoutes } from 'pages/landings/routes';
import { getUserData } from 'actions/user';
import { setPaymentRedirectUrl, startPollingDops, fetchTicket } from 'actions/orders';
import { sendEcommerceEvent } from 'actions/ecommerce';
import { ECOMMERCE_CACHE_NAME, ECOMMERCE_EVENTS } from 'constants/ecommerce';
import { toggleModal, toggleModalMode } from 'actions/auth';
import { addMessage as sysMessage } from 'actions/sysmessages';
import { cacheManager } from 'utils/cache-manager';
import { getIsOrderManage } from 'utils/paths';
import { getFeedbackUrl } from 'consts';
import Main from 'pages/main';
import CheckIn from 'pages/check-in';
import Authorize from 'pages/authorize';
import { checkinRoutes } from 'pages/check-in-new/pages';
import { orderServicesRoutes } from 'pages/order-services/pages';
import { upgradeRoutes } from 'pages/upgrade';
import { scheduleRoutes } from 'schedule/pages';
import { mailingRoutes } from 'pages/mailing';

const showSysMessage = (dispatch) => {
    const message = cacheManager.getItem('sysMessageOnReload');

    if (!message) {
        return;
    }

    dispatch(sysMessage(message), 'success');

    cacheManager.removeItem('sysMessageOnReload');
};

const processPaymentCallback = (inputStore) => (nextState) => {
    const { query } = nextState.location;

    if ('payment_success' in query) {
        const paymentSuccess = Number(query.payment_success);

        if (paymentSuccess) {
            const service = String(query.service || '');
            const rloc = String(query.rloc || '');
            const ecommerceCacheName = `${ECOMMERCE_CACHE_NAME}-${rloc}`;
            const ecommerceData = cacheManager.getItem(ecommerceCacheName);

            if (service && service) {
                inputStore.dispatch(sysMessage(t('orders.payment.success_service', { service }), 'success'));
            } else {
                inputStore.dispatch(sysMessage(t('orders.payment.success'), 'success'));
            }

            if (ecommerceData) {
                cacheManager.removeItem(ecommerceCacheName);
                inputStore.dispatch(sendEcommerceEvent(ECOMMERCE_EVENTS.PAYMENT_SUCCESSFUL, { ecommerceData }));
            } else {
                inputStore.dispatch(sendEcommerceEvent(ECOMMERCE_EVENTS.PAYMENT_SUCCESSFUL));
            }

            const orderUuid = Number(query.order_uuid);

            if (orderUuid) {
                inputStore.dispatch(fetchTicket(orderUuid));
            }
        } else {
            inputStore.dispatch(sysMessage(t('orders.payment.failed'), 'error'));
        }
    }
};

const onEnterRoot =
    (currentStore) =>
    ({ location }) => {
        const { dispatch, getState } = currentStore;

        dispatch(setPaymentRedirectUrl(window.location.origin + window.location.pathname));

        if (getState().auth.isAuthUser) {
            dispatch(getUserData());
        }

        if (location.query) {
            switch (location.query.change_personal_data) {
                case 'success':
                    dispatch(sysMessage(t('change_personal_data.payment_success_message'), 'success'));
                    break;
                case 'failure':
                    dispatch(sysMessage(t('change_personal_data.payment_failure_message'), 'error'));
                    break;
                default:
            }

            if (location.query.login === 'true') {
                dispatch(toggleModalMode(true));
                dispatch(toggleModal(true));
            }

            if (location.query.register === 'true') {
                dispatch(toggleModalMode(false));
                dispatch(toggleModal(true));
            }

            if (location.query.feedback === 'true') {
                window.location.href = getFeedbackUrl();
            }

            if (location.query.dops === 'true') {
                dispatch(startPollingDops({ rloc: location.query.rloc, orderId: location.query.order_id }));
            }

            if (location.query.dops === 'true') {
                dispatch(startPollingDops({ rloc: location.query.rloc, orderId: location.query.order_id }));
            }

            const isOrderManageRoute = getIsOrderManage(location.pathname);

            if (isOrderManageRoute && location.query.payment) {
                switch (location.query.payment) {
                    case 'success':
                        dispatch(sysMessage(t('order_manage.payment_success_message'), 'success'));
                        break;
                    case 'fail':
                        dispatch(sysMessage(t('order_manage.payment_fail_message'), 'error'));
                        break;
                    default:
                }
            }
        }

        if (['/', '/en', '/en/'].includes(location.pathname)) {
            processPaymentCallback(location.query, currentStore);
        }

        showSysMessage(dispatch);
    };

const onEnterProfile = (nextState, replaceState, { dispatch, getState }) => {
    const state = getState();

    if (!state.auth.isAuthUser) {
        replaceState({ nextPathname: nextState.location.pathname }, '/');
        dispatch(toggleModal(true));
    } else {
        dispatch(getUserData());
    }
};

const User = (props) => <DynamicImport load={() => import('pages/user')} {...props} />;

const BadLogin = (props) => <DynamicImport load={() => import('pages/BadLogin')} {...props} />;

const PilotMeals = (props) => <DynamicImport load={() => import('pages/pilot-meals')} {...props} />;

const OrderManage = (props) => <DynamicImport load={() => import('pages/order-manage')} {...props} />;

const Helicopters = (props) => <DynamicImport load={() => import('pages/helicopters/app')} {...props} />;

const ResetCachePage = (props) => <DynamicImport load={() => import('pages/ResetCache')} {...props} />;

const ContractBalances = (props) => <DynamicImport load={() => import('pages/contract-balances')} {...props} />;

const OnlinePanel = (props) => <DynamicImport load={() => import('pages/online-panel')} {...props} />;

const SignUp = (props) => <DynamicImport load={() => import('pages/signup')} {...props} />;

const AppSuspension = () => <DynamicImport load={() => import('pages/app-suspension')} />;

const routes = () => (
    <Route path="" onEnter={onEnterRoot(store)} component={Layout}>
        <IndexRedirect to="/" />

        <Route path="/">
            <IndexRoute component={Main} />

            <Route path="/app_suspension" component={AppSuspension} />
            <Route path="/1(/:paymentResult)" component={PilotMeals} />
            <Route path="check-in" component={CheckIn} />
            {checkinRoutes()}
            {upgradeRoutes()}
            {scheduleRoutes()}
            <Route path="order-manage" component={OrderManage} onEnter={processPaymentCallback(store)} />
            <Route path="helicopters" component={Helicopters} />
            <Route
                path="user"
                component={User}
                onEnter={(nextState, replaceState) => onEnterProfile(nextState, replaceState, store)}
            />

            {orderServicesRoutes(store)}

            {bonusesRoutes(store)}

            {landingsRoutes(store)}

            {mailingRoutes()}

            <Route path="/clearcookies" component={ResetCachePage} />
            <Route path="/contract-balances" component={ContractBalances} />

            <Route path="/information/onlinepanel" component={OnlinePanel} />

            <Route path="signup" component={SignUp} />
        </Route>

        <Route path="/en">
            <IndexRoute component={Main} />
            <Route path="app_suspension" component={AppSuspension} />
            <Route path="check-in" component={CheckIn} />
            {checkinRoutes()}
        </Route>

        <Route path="authorize" component={Authorize} />

        <Route path="payment_status/:status" component={PaymentStatus} />
        {process.env.BAD_LOGIN && <Route path="/badlogin" component={BadLogin} />}
    </Route>
);
export default routes;
