exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Passengers-module__inputGroup--tvGZq{padding:16px 32px}.Passengers-module__inputGroup--tvGZq:not(:last-child){border-bottom:1px solid #e4e4e4}.Passengers-module__groupCaption--1V6dS{padding-bottom:10px}.Passengers-module__groupItem--1qH48{padding:10px 0}.Passengers-module__tooltipPopover--2HG9y{z-index:200}.Passengers-module__tooltip--215m4{box-sizing:border-box;display:flex;justify-content:space-between;color:#fff;width:240px;height:auto;font-size:12px;line-height:22px;padding:8px 12px;border-radius:4px;background-color:rgba(0,53,148,.9)}.Passengers-module__itemWrapper--sLNNB{display:flex;align-items:center}.Passengers-module__infoIcon--31wEM{margin-left:4px;height:22px;width:22px;color:rgba(0,53,148,.3)}", ""]);

// exports
exports.locals = {
	"inputGroup": "Passengers-module__inputGroup--tvGZq",
	"groupCaption": "Passengers-module__groupCaption--1V6dS",
	"groupItem": "Passengers-module__groupItem--1qH48",
	"tooltipPopover": "Passengers-module__tooltipPopover--2HG9y",
	"tooltip": "Passengers-module__tooltip--215m4",
	"itemWrapper": "Passengers-module__itemWrapper--sLNNB",
	"infoIcon": "Passengers-module__infoIcon--31wEM"
};