/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from 'react';
import { t } from 'i18next';
import NumberInput from 'components/common/number.input-new';
import { UnaccompaniedMinorCheckbox } from 'entities/cart/ui/UnaccompaniedMinorCheckbox';

export function PassengersForm({
    passengers,
    passengersTotal,
    isFocused,
    maxCount,
    isStandbyTicketType,
    isCheckedUnaccompaniedMinor,
    handleFocus,
    handleMouseDown,
    handleKeyDown,
    setInputEl,
    onChangeAdultsCount,
    onChangeEmployeesCount,
    onChangeChildrenCount,
    onChangeInfantsCount,
    onCheckedUnaccompaniedMinor,
}) {
    const { adultsCount, childrenCount, infantsCount, employeesCount = 0 } = passengers;

    return (
        <div className="SearchWidget-box -passengers">
            <input
                onFocus={handleFocus}
                onMouseDown={handleMouseDown}
                onKeyDown={handleKeyDown}
                ref={setInputEl}
                className="SearchWidget-input -select"
                type="text"
                value={t('booking.passenger_interval', {
                    postProcess: 'interval',
                    count: passengersTotal,
                })}
                readOnly
                tabIndex="1"
            />

            <div className="SearchWidget-dropdown -padding" aria-hidden={!isFocused}>
                {isStandbyTicketType && (
                    <div className="Grid-2 -middle -nogutter !mb24">
                        <p className="Col">{t('booking.employees')}</p>
                        <div className="Col">
                            <NumberInput
                                /** Параметр key нужен для правильного обновления, почему так смотри ссылку:
                                 * https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html */
                                key={`employees${employeesCount}`}
                                className="block-right"
                                value={employeesCount}
                                minValue={adultsCount ? 0 : 1}
                                maxValue={maxCount.employees}
                                onChange={onChangeEmployeesCount}
                            />
                        </div>
                    </div>
                )}

                <div className="Grid-2 -middle -nogutter !mb24">
                    <p className="Col">
                        {t(`booking.${!isStandbyTicketType ? 'adults' : 'relative'}`)}
                        {isStandbyTicketType && <span className="caption block">Взрослый</span>}
                    </p>
                    <div className="Col">
                        <NumberInput
                            key={`adults${adultsCount}`}
                            className="block-right"
                            value={adultsCount}
                            minValue={employeesCount ? 0 : 1}
                            maxValue={maxCount.adults}
                            onChange={onChangeAdultsCount}
                        />
                    </div>
                </div>

                <div className="Grid-2 -middle -nogutter !mb24">
                    <p className="Col">
                        {t(`booking.${isStandbyTicketType ? 'relative' : 'children'}`)}
                        {isStandbyTicketType && <span className="caption block">До 12 лет</span>}
                    </p>
                    <div className="Col">
                        <NumberInput
                            key={`children${childrenCount}`}
                            className="block-right"
                            value={childrenCount}
                            minValue={0}
                            maxValue={maxCount.children}
                            onChange={onChangeChildrenCount}
                        />
                    </div>
                </div>

                <div className="Grid-2 -middle -nogutter">
                    <p className="Col">
                        {t(`booking.${isStandbyTicketType ? 'relative' : 'infants'}`)}
                        <span className="caption block">
                            {isStandbyTicketType ? 'До 2 лет без места' : t('booking.infants_note')}
                        </span>
                    </p>
                    <div className="Col">
                        <NumberInput
                            key={`infants${infantsCount}`}
                            className="block-right"
                            value={infantsCount}
                            minValue={0}
                            maxValue={maxCount.infants}
                            onChange={onChangeInfantsCount}
                        />
                    </div>
                </div>
                {!isStandbyTicketType && (
                    <div className="Grid-2 -middle -nogutter !mt24">
                        <div className="Col line">
                            <UnaccompaniedMinorCheckbox
                                isChecked={isCheckedUnaccompaniedMinor}
                                onChange={onCheckedUnaccompaniedMinor}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
